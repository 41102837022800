
.site-header {
  background-color: #300457;
  /* background-color: 300457#7af3b8; */
  height: 2em;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: left; */
  /* justify-content: center; */
  font-size: calc(10px + 1em);
  padding: 0.25em;
  position: sticky;
  top: 0vh;
  z-index: 13;
}

.site-header .logo {
  float: left;
  color: white;
  line-height: 2em;
}

.site-header .actions {
  float: right;
  line-height: 1.5em;
}

.portfolio-thumbnail {
  text-align: center;
}

.portfolio-thumbnail img {
  width: 100%;
}